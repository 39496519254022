.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: transparent;
  font-size: 18px;
  line-height: 1.44444444;
  font-family: inherit;
  cursor: pointer;
  color: #000;
  padding: 5px 40px;
  appearance: none;
  border-radius: 10000px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  border: 2px solid #000;
  transition: all 0.24s;
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #D80011, #D80011);
}
.button:hover,
.button:focus {
  background-size: 100% 100%;
  border-color: #D80011;
  color: #fff;
}
.section--three .subcontent {
  width: calc(50% - 40px);
}
.area--one {
  width: calc(100% + 30px);
  margin-left: -15px;
}
.area--one .unit {
  margin-left: 15px;
  margin-right: 15px;
  width: calc(100% - 30px);
}
.area--one .unitOne {
  width: calc(50% - 30px);
}
.area--one .unitTwo {
  width: calc(50% - 30px);
}
.area--two .unitOne--1-2 {
  width: 50%;
}
.area--two .unitTwo .unit__content {
  width: 50%;
}
.area--two .unitTwo--alignImageLeft {
  justify-content: flex-end;
}
.area--two .unitTwo--alignImageLeft .unit__content {
  margin-left: auto;
  padding-left: var(--horizontalPadding);
}
.area--two .unitTwo--alignImageRight .unit__background {
  order: 2;
}
.area--two .unitTwo--alignImageRight .unit__content {
  order: 1;
  padding-right: var(--horizontalPadding);
}
.area--two .unitThree .unit__body {
  width: calc(100% + 30px);
  margin-left: -15px;
}
.area--two .unitThree .part {
  margin-left: 15px;
  margin-right: 15px;
  width: calc((100% / 3) - 30px);
}
.area--two .unit--form {
  width: 50%;
}
.area--three {
  margin-top: calc(var(--verticalSpaceLarge) - var(--spaceTotal));
}
/*# sourceMappingURL=./screen-medium.css.map */